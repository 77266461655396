<template>
  <div>
     <b-modal @hidden="resetModal" ref="modal-pg-edit" size="xl" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-credit-card me-1 text-primary"></i>  Payment Gateway Setting {{ '- '+ updateData.payment_gateway_name || '' }}
           </h5>
        </template>
          <div class="row align-items-top">
              <div class="mb-3 col-12 col-lg">
                 <label class="mb-1">Payment Gateway</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ updateData.payment_gateway_name || '-' }}</span></div>
              </div>
              <div class="mb-3 col-12 col-lg">
                <div class="align-items-center">
                  <label class="mb-1 me-2">Payment Gateway Code</label>
                  <div><span class="badge bg-soft-primary font-size-14">{{ updateData.payment_gateway_code || '-' }}</span></div>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg">
                <div class="align-items-center">
                  <label class="mb-1 me-2">Country</label>
                  <div><span class="badge bg-soft-primary font-size-14">{{ `${updateData.country} (${updateData.currency})` ||  '-' }}</span></div>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-auto">
                <div class="align-items-center">
                  <label class="mb-1 me-2">Active</label>
                  <div>
                    <div class="form-check form-switch  form-check-custom ms-1 mb-0 font-size-15">
                      <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.status" true-value="1" false-value="0" :disabled="loading">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-12">
                <label class="mb-1 me-2">Category</label>
                <multiselect v-model="formEditData.category" :options="pgCategory" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Category" label="" track-by="" class="text-capitalize" :preselect-first="true" :disabled="loading" :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.category.$error,
                  }">
                 <template slot="option" slot-scope="props"><span class="option__title text-capitalize cursor-pointer">{{ props.option }}</span></template>
                 <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag"><span class="badge font-size-14 p-1 px-2 me-1 mb-0 bg-soft-warning text-orange text-capitalize cursor-pointer">{{ option }} <span class="custom__remove" @click="remove(option)"><i class="uil uil-minus-circle"></i></span></span>
                 </span>
                  </template>
                 <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                
                 <div v-if="modalSubmit && $v.formEditData.category.$error" class="invalid-feedback">
                  <span v-if="!$v.formEditData.category.required">Category is required.</span>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-12">
                <label class="mb-1 me-2">Support Type</label>
                <div v-if="updateData.supported_type" class="text-capitalize"><span  class="badge bg-soft-primary font-size-14 me-2" v-for="(value, index) in updateData.supported_type.split(',')" :key="index">{{ value }}</span></div>
              </div>
              <hr>
              <div class="col-12 mb-3">
                    <div class="progress progress-stacked mb-2" style="height: 36px; font-size: 16px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary font-size-14" v-if="updateData.wallet_info" :style="`width: ${parseFloat(updateData.wallet_info.deposit_balance/updateData.balance_limit)*100}%;`"> {{  parseFloat(parseFloat(updateData.wallet_info.deposit_balance/updateData.balance_limit) *100).toFixed(2)}}%</div>
                    </div>
                    <div class="d-lg-flex w-100">
                      <span v-if="updateData.wallet_info">Total Deposit: {{ convertCurrencyFormat(updateData.wallet_info.deposit_balance,true) }} (System)</span>
                      <span v-if="updateData.balance_limit" class="ms-lg-auto d-block d-lg-inline">Balance Limit: {{ convertCurrencyFormat(updateData.balance_limit,false) }}</span>
                    </div>
              </div>
              <div class="col-12 mb-3">
                    <div class="progress progress-stacked mb-2" style="height: 36px; font-size: 16px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                      <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary font-size-14" v-if="updateData.wallet_info" :style="`width: ${parseFloat(updateData.wallet_info.deposit_balance/updateData.balance_limit)*100}%;`"> {{  parseFloat(parseFloat(updateData.wallet_info.deposit_balance/updateData.balance_limit) *100).toFixed(2)}}%</div>
                    </div>
                    <div class="d-lg-flex w-100">
                      <span v-if="updateData.wallet_info">Total Deposit: {{ convertCurrencyFormat(updateData.wallet_info.deposit_balance,true) }} (PG)</span>
                      <span v-if="updateData.balance_limit" class="ms-lg-auto d-block d-lg-inline">Balance Limit: {{ convertCurrencyFormat(updateData.balance_limit,false) }}</span>
                    </div>
              </div>
              <div class="mb-3 col-12 col-lg-4">
                 <label class="mb-1">Min. Deposit</label>
                 <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.min_deposit.$error}" 
                  v-model="formEditData.min_deposit" placeholder="Min. Deposit" @input="inputNumberOnly($event, 'formEditData','min_deposit')" :disabled="loading">
                <div
                  v-if="modalSubmit && $v.formEditData.min_deposit.$error"
                  class="invalid-feedback"
                  >
                  <span v-if="!$v.formEditData.min_deposit.required"
                    >Min. Deposit is required. </span
                    >
                </div>
              </div>
              <div class="mb-4 col-12 col-lg-4">
                <label class="mb-1">Max. Deposit</label>
                <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.max_deposit.$error}" 
                    v-model="formEditData.max_deposit" placeholder="Max. Deposit" @input="inputNumberOnly($event, 'formEditData','max_deposit')" :disabled="loading">
                  <div
                    v-if="modalSubmit && $v.formEditData.max_deposit.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.max_deposit.required"
                      >Max. Deposit is required. </span
                      >
                  </div>
              </div>

              <div class="mb-4 col-12 col-lg-4">
                <label class="mb-1">Balance Limit</label>
                <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.balance_limit.$error}" 
                    v-model="formEditData.balance_limit" placeholder="Balance Limit" @input="inputNumberOnly($event, 'formEditData','balance_limit')" :disabled="loading">
                  <div
                    v-if="modalSubmit && $v.formEditData.balance_limit.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.balance_limit.required"
                      >Balance limit is required. </span
                      >
                  </div>
              </div>
          
              <hr>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Payin Rate and Extra Charges</label>
                 <div class="row">
                    <div class="col-6">
                      <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payin_rate.$error}" 
                        v-model="formEditData.payin_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','payin_rate')" >
                      <div
                        v-if="modalSubmit && $v.formEditData.payin_rate.$error"
                        class="invalid-feedback"
                        >
                        <span v-if="!$v.formEditData.payin_rate.required"
                          >The payin rate is required.</span
                          >
                        <span v-else-if="!$v.formEditData.payin_rate.between"
                          >The payin rate should be above 0.10% but below 50%.</span
                          > 
                      </div>
                    </div>
                    <div class="col-6">
                      <input type="text" inputmode="decimal" class="form-control text-start" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payin_extra_charge.$error}" 
                        v-model="formEditData.payin_extra_charge" placeholder="Extra charge" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','payin_extra_charge')" >
                      <div
                        v-if="modalSubmit && $v.formEditData.payin_extra_charge.$error"
                        class="invalid-feedback"
                        >
                        <span v-if="!$v.formEditData.payin_extra_charge.required"
                          >The extra charge is required. </span
                          >
                        <span v-else-if="!$v.formEditData.payin_extra_charge.between"
                          >The extra charge can be 0.00 or any value above 0.00.</span
                          > 
                      </div>
                    </div>
                  </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                <label class="mb-1">Payout Rate and Extra Charges</label>
                <div class="row">
                  <div class="col-6">
                    <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payout_rate.$error}" 
                      v-model="formEditData.payout_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','payout_rate')" >
                    <div
                      v-if="modalSubmit && $v.formEditData.payout_rate.$error"
                      class="invalid-feedback"
                      >
                      <span v-if="!$v.formEditData.payout_rate.required"
                        >The payout rate is required.</span
                        >
                      <span v-else-if="!$v.formEditData.payout_rate.between"
                        >The payout rate should be above 0.10% but below 50%.</span
                        > 
                    </div>
                  </div>
                  <div class="col-6">
                    <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payout_extra_charge.$error}" 
                      v-model="formEditData.payout_extra_charge" placeholder="Extra charge" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','payout_extra_charge')" >
                    <div
                      v-if="modalSubmit && $v.formEditData.payout_extra_charge.$error"
                      class="invalid-feedback"
                      >
                      <span v-if="!$v.formEditData.payout_extra_charge.required"
                        >The extra charge is required. </span
                        >
                      <span v-else-if="!$v.formEditData.payout_extra_charge.between"
                        >The extra charge can be 0.00 or any value above 0.00.</span
                        > 
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                <label class="mb-1">Settlement Rate and Extra Charges</label>
                <div class="row">
                  <div class="col-6">
                    <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.settlement_rate.$error}" 
                      v-model="formEditData.settlement_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','settlement_rate')" >
                    <div
                      v-if="modalSubmit && $v.formEditData.settlement_rate.$error"
                      class="invalid-feedback"
                      >
                      <span v-if="!$v.formEditData.settlement_rate.required"
                        >The settlement rate is required.</span
                        >
                      <span v-else-if="!$v.formEditData.settlement_rate.between"
                        >The settlement rate should be above 0.10% but below 50%.</span
                        > 
                    </div>
                  </div>
                  <div class="col-6">
                    <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.settlement_extra_charge.$error}" 
                      v-model="formEditData.settlement_extra_charge" placeholder="Extra charge" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','settlement_extra_charge')" >
                    <div
                      v-if="modalSubmit && $v.formEditData.settlement_extra_charge.$error"
                      class="invalid-feedback"
                      >
                      <span v-if="!$v.formEditData.settlement_extra_charge.required"
                        >The extra charge is required. </span
                        >
                      <span v-else-if="!$v.formEditData.settlement_extra_charge.between"
                        >The extra charge can be 0.00 or any value above 0.00.</span
                        > 
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                <label class="mb-1">E-Wallet Rate and  Extra Charges</label>
                <div class="row">
                  <div class="col-6">
                    <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.ewallet_rate.$error}" 
                      v-model="formEditData.ewallet_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','ewallet_rate')" >
                    <div
                      v-if="modalSubmit && $v.formEditData.ewallet_rate.$error"
                      class="invalid-feedback"
                      >
                      <span v-if="!$v.formEditData.ewallet_rate.required"
                        >The e-wallet rate is required.</span
                        >
                      <span v-else-if="!$v.formEditData.ewallet_rate.between"
                        >The e-wallet rate should be above 0.10% but below 50%.</span
                        > 
                    </div>
                  </div>
                  <div class="col-6">
                    <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.ewallet_extra_charge.$error}" 
                      v-model="formEditData.ewallet_extra_charge" placeholder="Extra charge" maxlength="5" @input="inputNumberOnlyV2($event, 'formEditData','ewallet_extra_charge')" >
                    <div
                      v-if="modalSubmit && $v.formEditData.ewallet_extra_charge.$error"
                      class="invalid-feedback"
                      >
                      <span v-if="!$v.formEditData.ewallet_extra_charge.required"
                        >The extra charge is required. </span
                        >
                      <span v-else-if="!$v.formEditData.ewallet_extra_charge.between"
                        >The extra charge can be 0.00 or any value above 0.00.</span
                        > 
                    </div>
                  </div>
                </div>
              </div>
              <input type="text" class="form-control" v-model="formEditData.currency" hidden>
              
              <div class="col-12">
                  <hr>
                  <div class="d-grid gap-2 d-md-block text-end">
                    <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-pg-edit'].hide()">Cancel</button>
                    <button type="button" class="btn fw-medium btn-info" @click="updatePgSetting" :disabled="modalLoading || loading">
                    <span v-if="modalLoading"> Updating...</span>
                    <span v-else> Update </span>
                    <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                    </button>
                  </div>
              </div>
        </div>
     </b-modal>
     <Common ref="commonFunc" />
  </div>
</template>
<script>
  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import Multiselect from "vue-multiselect";
  import { 
    required, 
    between
    //url
  } from "vuelidate/lib/validators";

  /**
   * Starter page
   */

  export default {
    components: {
      Common,
      Multiselect
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        updateData: {},
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        account_type: '',
        loading: false,
        modalSubmit: false,
        modalLoading: false,
        pgCategory:['gaming','forex','crypto','porn','scam'],
        formData: {
          username:"",
          password: "",
          confirmPassword: "",
          name:"",
          websiteUrl:"",
          whitelistIP:"",
          callBackUrl:"",
          remarks:"",
          category:[],
          contract_selected:[],
        },
        formEditData: {
          id:"",
          status:"",
          category:[],
          min_deposit:"",
          max_deposit:"",
          balance_limit:"",
          currency:"",
          payin_rate: "",
          payin_extra_charge: "0",
          payout_rate: "",
          payout_extra_charge: "0",
          settlement_rate: "",
          settlement_extra_charge: "0",
          ewallet_rate: "",
          ewallet_extra_charge: "0",
        }
      }
    },
    validations: {
      formEditData: {
        currency: {
          required,
        },
        min_deposit: {
          required,
        },
        max_deposit: {
          required,
        },
        balance_limit: {
          required,
        },
        payin_rate: {
          required,
          between: between(0.1,50)
        },
        payin_extra_charge: {
          required,
          between: between(0,100000)
        },
        payout_rate: {
          required,
          between: between(0.1,50)
        },
        payout_extra_charge: {
          required,
          between: between(0,100000)
        },
        settlement_rate: {
          required,
          between: between(0.0,50)
        },
        settlement_extra_charge: {
          required,
          between: between(0,100000)
        },
        ewallet_rate: {
          required,
          between: between(0.0,50)
        },
        ewallet_extra_charge: {
          required,
          between: between(0,100000)
        },
        category: {
          required,
        },
      }
    },
    middleware: "authentication",
    async mounted() {
      this.reload();
      //   this.title = PageTitle
      //   this.items[1].text = PageTitle
      //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
      //   // this.$refs.main.changeVerticalTopBar("bill",true)
      //   // this.$refs.main.setShowFooterCert(false)
      //   // this.$refs.main.setPageTitle('title')
      //   console.log( this.$refs.commonFunc.getPhone())
      //   this.accessToken = this.$refs.commonFunc.getToken()
      //   this.accessUsername = this.$refs.commonFunc.getUsername()
    },
    created() {
    },
    methods: {
   
    inputNumberOnly(event, parentModel, childModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this[parentModel][childModel] = numericValue
    },
    inputNumberOnlyV2(event, parentModel, childModel) {
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this[parentModel][childModel] = numericValue
      // if(numericValue.length > 2){
      //   var val = numericValue.replace(/[^0-9]/g, '');
      //   console.log(val);
      //   val = val.substr(0, val.length-2)+"."+val.substr(-2);
      //   this[parentModel][childModel] = val;
      // }
    },
    inputNumberOnlyV3(event, parentModel, childModel, index) {
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      //this[parentModel][childModel] = numericValue
      this[parentModel].contract_selected[index][childModel] = numericValue
      if(numericValue.length > 2){
        var val = numericValue.replace(/[^0-9]/g, '');
        console.log(val);
        val = val.substr(0, val.length-2)+"."+val.substr(-2);
        this[parentModel].contract_selected[index][childModel] = val;
      }
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    showModal() {
      this.$refs['modal-merchant'].show()
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
    },
    showEditModal() {
      this.$refs['modal-pg-edit'].show()
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData

      this.formEditData= {
          id: this.data.updateData.payment_db_code,
          status: this.data.updateData.status,
          category: this.data.updateData.categories,
          min_deposit: this.data.updateData.min_deposit,
          max_deposit:this.data.updateData.max_deposit,
          balance_limit:this.data.updateData.balance_limit,
          currency:this.data.updateData.currency,
          payin_rate: this.data.updateData.payin_rate,
          payin_extra_charge: this.data.updateData.payin_extra_charge,
          payout_rate: this.data.updateData.payout_rate,
          payout_extra_charge: this.data.updateData.payout_extra_charge,
          settlement_rate:this.data.updateData.settlement_rate,
          settlement_extra_charge: this.data.updateData.settlement_extra_charge,
          ewallet_rate: this.data.updateData.ewallet_rate,
          ewallet_extra_charge: this.data.updateData.ewallet_extra_charge,
      }
      //this.getReseller('edit');
    },
    resetModal() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.formData={
        username:"",
        password: "",
        confirmPassword: "",
        name:"",
        websiteUrl:"",
        whitelistIP:"",
        callBackUrl:"",
        remarks:"",
        category: "",
        contract_selected:[],
      }
      this.formEditData = {
        id:"",
        status:"",
        category:[],
        min_deposit:"",
        max_deposit:"",
        currency:"",
        payin_rate: "",
        payin_extra_charge: "0",
        payout_rate: "",
        payout_extra_charge: "0",
        settlement_rate: "",
        settlement_extra_charge: "0",
        ewallet_rate: "",
        ewallet_extra_charge: "0",
      }
    },
    updatePgSetting(){
      this.modalSubmit = true
      this.$v.formEditData.$touch();
      if (this.$v.formEditData.$invalid) {
        return;
      }
     else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("pgCode", this.formEditData.id);
        bodyFormData.append("status", this.formEditData.status);
        bodyFormData.append("categories", JSON.stringify(this.formEditData.category));
        bodyFormData.append("currency", this.formEditData.currency);
        bodyFormData.append("minumumDeposit", this.formEditData.min_deposit);
        bodyFormData.append("maximumDeposit", this.formEditData.max_deposit);
        bodyFormData.append("balanceLimit", this.formEditData.balance_limit);
        bodyFormData.append("payinRate", this.formEditData.payin_rate);
        bodyFormData.append("payinExtraCharges", this.formEditData.payin_extra_charge);
        bodyFormData.append("payoutRate", this.formEditData.payout_rate);
        bodyFormData.append("payoutExtraCharges", this.formEditData.payout_extra_charge);
        bodyFormData.append("settlementRate", this.formEditData.settlement_rate);
        bodyFormData.append("settlementExtraCharges", this.formEditData.settlement_extra_charge);
        bodyFormData.append("ewalletRate", this.formEditData.ewallet_rate);
        bodyFormData.append("ewalletExtraCharges", this.formEditData.ewallet_extra_charge);

        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/updatePaymentGatewaySetting',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: `${this.updateData.payment_gateway_name} Setting Updated`,
                html: 'Payment gateway setting has been successfully updated!',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-pg-edit'].hide()
                this.$emit('callParentFunction');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted
        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
    }
  }
</script>
<style scoped>
  #eye_current,
  #eye,
  #eye2,
  #eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
  }
  .custom-table td .row{
    min-width:220px;
    
  }
</style>