<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="bi bi-coin display-6 text-primary" style="font-size: 28px;"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    {{ title }}
                  </h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Company Cost Setting</small>
                </div>
              </div>
              <div class="ms-auto d-none" v-if="account_type=='admin'">
                <button class="btn btn-outline-info fw-medium">
                <i class="uil uil-plus font-size-16 me-1"></i> Add Payment Gateway
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row align-items-center mb-2">
              <div class="col-12">
                <div class="d-md-flex">
                  <div class="d-flex col-12 col-md-4 me-md-2 mb-3">
                    <div class="input-group">
                      <div class="input-group-text py-0 custom_top_data_bar text-white border-dark fw-medium"><i class="bi bi-coin me-1 text-white"></i> Currency</div>
                            <multiselect v-model="selectedCurrency" :options="currencyList" label="name" track-by="name" class="form-control m-0 p-1" placeholder="Select a Currency" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="loading" @input="getData()">
                            <template slot="singleLabel" slot-scope="props">
                              <span v-if="props.option.value!==''">
                                <span class="option__title">{{ props.option.name }} ({{ props.option.value }})</span>
                              </span>
                            </template>
                            <template slot="option" slot-scope="props">
                              <span v-if="props.option.name!==''" class="option__title">{{ props.option.name }}</span>
                            </template>
                            
                            <span slot="noResult">Oops! No currency found. </span>
                          </multiselect>
                        </div>
                    
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-auto d-none">
                <div class="row">
                  <div class="col-6 pe-1 d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      @click="clear()"
                      >
                    <i class="uil-redo me-1"></i> Reset
                    </button>
                  </div>
                  <div class="col-6 ps-1 d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      @click="search()"
                      >
                    <i class="uil-search me-1"></i> Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div>
                <h4 class="fw-medium font-size-18 text-primary mb-3"> Cost Setting ({{ selectedCurrency.value ||'-' }})</h4>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="mb-3 row">
                    <label class="col-lg-auto col-form-label">Min. Deposit</label>
                    <div class="col-lg">

                      <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.min_deposit.$error}" 
                        v-model="formEditData.min_deposit" placeholder="Min. Deposit" @input="inputNumberOnlyV2($event, 'formEditData','min_deposit')" :disabled="loading">
                      <div
                        v-if="modalSubmit && $v.formEditData.min_deposit.$error"
                        class="invalid-feedback"
                        >
                        <span v-if="!$v.formEditData.min_deposit.required"
                          >Min. Deposit is required. </span
                          >
                         
                         
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="mb-3 row">
                    <label class="col-lg-auto col-form-label">Max. Deposit</label>
                    <div class="col-lg">
                      <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.max_deposit.$error}" 
                        v-model="formEditData.max_deposit" placeholder="Max. Deposit" @input="inputNumberOnlyV2($event, 'formEditData','max_deposit')" :disabled="loading">
                      <div
                        v-if="modalSubmit && $v.formEditData.max_deposit.$error"
                        class="invalid-feedback"
                        >
                        <span v-if="!$v.formEditData.max_deposit.required"
                          >Max. Deposit is required. </span
                          >
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="table-responsive text-nowrap font-size-14">
                  <table class="table align-middle" >
                    <thead class="custom_top_data_bar text-uppercase font-size-12">
                      <tr>
                        <th>Company</th>
                        <th>Payin Rate and Extra Charges</th>
                        <th>Payout Rate and Extra Charges</th>
                        <th>Settlement Rate and Extra Charges</th>
                        <th>E-Wallet Rate and  Extra Charges</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loading">
                        <td colspan="5" class="text-center text-muted" height="120">
                          <div
                            class="spinner-border text-secondary my-2"
                            role="status"
                            >
                            <span class="sr-only">Loading...</span>
                          </div>
                          <br />
                          <div>Loading...</div>
                        </td>
                      </tr>
                      <tr v-if="!loading">
                        <td class="border-end">Cost Setting
                          <input type="text" class="form-control" v-model="formEditData.currency" hidden>
                        </td>
                        <td class="align-top border-end">
                          <div class="row">
                            <div class="col-12 col-lg-6 mb-2 mb-lg-0">
                              <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payin_rate.$error}" 
                                v-model="formEditData.payin_rate" placeholder="Rate" maxLength="8" @input="inputNumberOnlyV2($event, 'formEditData','payin_rate')" >
                              <div
                                v-if="modalSubmit && $v.formEditData.payin_rate.$error"
                                class="invalid-feedback"
                                >
                                <span v-if="!$v.formEditData.payin_rate.required"
                                  >The payin rate is required.</span
                                  >
                                <span v-else-if="!$v.formEditData.payin_rate.between"
                                  >The payin rate should be above 0.10% but below 50%.</span
                                  > 
                              </div>
                            </div>
                            <div class="col-12 col-lg-6">
                              <input type="text" inputmode="decimal" class="form-control text-start" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payin_extra_charge.$error}" 
                                v-model="formEditData.payin_extra_charge" placeholder="Extra charge" maxLength="8" @input="inputNumberOnlyV2($event, 'formEditData','payin_extra_charge')" >
                              <div
                                v-if="modalSubmit && $v.formEditData.payin_extra_charge.$error"
                                class="invalid-feedback"
                                >
                                <span v-if="!$v.formEditData.payin_extra_charge.required"
                                  >The extra charge is required. </span
                                  >
                                <span v-else-if="!$v.formEditData.payin_extra_charge.between"
                                  >The extra charge can be 0.00 or any value above 0.00.</span
                                  > 
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="align-top border-end">
                          <div class="row">
                            <div class="col-12 col-lg-6 mb-2 mb-lg-0">
                              <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payout_rate.$error}" 
                                v-model="formEditData.payout_rate" placeholder="Rate" maxLength="8" @input="inputNumberOnlyV2($event, 'formEditData','payout_rate')" >
                              <div
                                v-if="modalSubmit && $v.formEditData.payout_rate.$error"
                                class="invalid-feedback"
                                >
                                <span v-if="!$v.formEditData.payout_rate.required"
                                  >The payout rate is required.</span
                                  >
                                <span v-else-if="!$v.formEditData.payout_rate.between"
                                  >The payout rate should be above 0.10% but below 50%.</span
                                  > 
                              </div>
                            </div>
                            <div class="col-12 col-lg-6">
                              <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.payout_extra_charge.$error}" 
                                v-model="formEditData.payout_extra_charge" placeholder="Extra charge" maxLength="8" @input="inputNumberOnlyV2($event, 'formEditData','payout_extra_charge')" >
                              <div
                                v-if="modalSubmit && $v.formEditData.payout_extra_charge.$error"
                                class="invalid-feedback"
                                >
                                <span v-if="!$v.formEditData.payout_extra_charge.required"
                                  >The extra charge is required. </span
                                  >
                                <span v-else-if="!$v.formEditData.payout_extra_charge.between"
                                  >The extra charge can be 0.00 or any value above 0.00.</span
                                  > 
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="align-top border-end">
                          <div class="row">
                            <div class="col-12 col-lg-6 mb-2 mb-lg-0">
                              <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.settlement_rate.$error}" 
                                v-model="formEditData.settlement_rate" placeholder="Rate" maxLength="8" @input="inputNumberOnlyV2($event, 'formEditData','settlement_rate')" >
                              <div
                                v-if="modalSubmit && $v.formEditData.settlement_rate.$error"
                                class="invalid-feedback"
                                >
                                <span v-if="!$v.formEditData.settlement_rate.required"
                                  >The settlement rate is required.</span
                                  >
                                <span v-else-if="!$v.formEditData.settlement_rate.between"
                                  >The settlement rate should be above 0.10% but below 50%.</span
                                  > 
                              </div>
                            </div>
                            <div class="col-12 col-lg-6">
                              <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.settlement_extra_charge.$error}" 
                                v-model="formEditData.settlement_extra_charge" placeholder="Extra charge" maxLength="8" @input="inputNumberOnlyV2($event, 'formEditData','settlement_extra_charge')" >
                              <div
                                v-if="modalSubmit && $v.formEditData.settlement_extra_charge.$error"
                                class="invalid-feedback"
                                >
                                <span v-if="!$v.formEditData.settlement_extra_charge.required"
                                  >The extra charge is required. </span
                                  >
                                <span v-else-if="!$v.formEditData.settlement_extra_charge.between"
                                  >The extra charge can be 0.00 or any value above 0.00.</span
                                  > 
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="align-top">
                          <div class="row">
                            <div class="col-12 col-lg-6 mb-2 mb-lg-0">
                              <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.ewallet_rate.$error}" 
                                v-model="formEditData.ewallet_rate" placeholder="Rate" maxLength="8" @input="inputNumberOnlyV2($event, 'formEditData','ewallet_rate')" >
                              <div
                                v-if="modalSubmit && $v.formEditData.ewallet_rate.$error"
                                class="invalid-feedback"
                                >
                                <span v-if="!$v.formEditData.ewallet_rate.required"
                                  >The e-wallet rate is required.</span
                                  >
                                <span v-else-if="!$v.formEditData.ewallet_rate.between"
                                  >The e-wallet rate should be above 0.10% but below 50%.</span
                                  > 
                              </div>
                            </div>
                            <div class="col-12 col-lg-6">
                              <input type="text" inputmode="decimal" class="form-control" :class="{ 'is-invalid': modalSubmit && $v.formEditData.ewallet_extra_charge.$error}" 
                                v-model="formEditData.ewallet_extra_charge" placeholder="Extra charge" maxLength="8" @input="inputNumberOnlyV2($event, 'formEditData','ewallet_extra_charge')" >
                              <div
                                v-if="modalSubmit && $v.formEditData.ewallet_extra_charge.$error"
                                class="invalid-feedback"
                                >
                                <span v-if="!$v.formEditData.ewallet_extra_charge.required"
                                  >The extra charge is required. </span
                                  >
                                <span v-else-if="!$v.formEditData.ewallet_extra_charge.between"
                                  >The extra charge can be 0.00 or any value above 0.00.</span
                                  > 
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="!loading">
                        <td class="align-middle text-end bg-light" colspan="5">
                          <div v-if="create_modify_permission">
                            <button type="button" class="btn fw-medium btn-info" @click="updateCompanyCost" :disabled="modalLoading || loading">
                            <span v-if="modalLoading"> Updating...</span>
                            <span v-else> Update Cost </span>
                            <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                            </button>
                          </div>
                          <div v-else>
                            <button type="button" class="btn fw-medium btn-info" disabled="true">
                              Update Cost
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <pre>{{ estData }}</pre> -->
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <b-modal
      @hidden="resetModal"
      ref="modal-extra-info"
      centered
      hide-footer
      size="lg"
      hide-header
      :no-close-on-backdrop="true"
      content-class="overflow-hidden"
      >
      <div class="row align-items-center">
        <div class="col-12 text-white" >
          <h5 class="text-info mb-3">{{extraInfo.payment_gateway_name}} - Extra Info</h5>
        </div>
        <div class="col-12 text-muted">
         <pre v-if="extraInfo">{{ JSON.parse(extraInfo.extra_info|| '{}') }}</pre>
          <div class="d-grid gap-2 d-md-block text-end">
            <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-extra-info'].hide()">Cancel</button>
          </div>
        </div>
      </div>
    </b-modal>
    <pgModal ref="modalFunc" :data="{'accessUsername':accessUsername, 'assessToken':accessToken, 'updateData':updateData}"  @callParentFunction="getData()" />
  </Layout>
</template>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Lottie from "@/components/lottieView";
  import Common from "@/components/common";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Multiselect from "vue-multiselect";
  import { required, minLength, sameAs,between, helpers } from "vuelidate/lib/validators";
  import pgModal from "@/views/pages/app/modal/createEditPg";

  const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
  const validWebsiteUrl = (value) => {
    console.log(value);
    const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
    return regex.test(value);
  }
  const validUsername = (value) => {
    const regex = /^[a-z0-9]+$/;
    return regex.test(value);
  };
  


  /**
   * Starter page
   */
  const PageTitle = "Company Setting"
  const currencyDefault = {
    "name":"Select Currency",
    "value":""
  }
  export default {
    components: { 
      Layout, 
      PageHeader, 
      Lottie,
      Common,
      Multiselect,
      pgModal
    },
    page() {
      return {
        title: PageTitle,
        meta: [
          {
            name: "description",
            content: appConfig.description,
          },
        ],
      }
    },
    data() {
      return {
        defaultName: appConfig.defaultAccountName,
        search_value:'',
        searchKey:'',
        searchKey2:'',
        accessToken:'',
        accessEmail:'',
        accessPhone:'',
        accessUsername:'',
        account_type:"",
        showLottie:false,
        updateData:{},
        title: '',
        items: [
          {
            text: appConfig.pageTitle,
            to: "/",
          },
          {
            text: '',
            active: true,
          },
        ],
        passwordFieldType_current: "password",
        classicon_current: "uil uil-eye-slash",
        passwordFieldType: "password",
        classicon: "uil uil-eye-slash",
        passwordFieldType2: "password",
        classicon2: "uil uil-eye-slash",
        currentPage:1,
        perPage: 20,
        totalData: 0,
        submitted:false,
        modalSubmit:false,
        modalLoading:false,
        modalLoading2:false,
        loading:true,
        loading2:true,
        buttonLoading:[false,false,false],
        returnData: [],
        selectedCurrency: currencyDefault,
        currencyList:[],
        extraInfo:{},
        formData: {
          username:"",
          name:"",
          apiKey: "",
          paymentUrl: "",
          returnUrl: "",
          websiteUrl: "",
          password: "",
          confirmPassword: "",
          otp: "",
        },
        formEditData: {
          id:"",
          min_deposit:"",
          max_deposit:"",
          currency:"",
          payin_rate: "",
          payin_extra_charge: "0",
          payout_rate: "",
          payout_extra_charge: "0",
          settlement_rate: "",
          settlement_extra_charge: "0",
          ewallet_rate: "",
          ewallet_extra_charge: "0",
        },
        create_modify_permission:false
      };
    },
    validations: {
      formData: {
        username: {
          required,
          validUsername
        },
        name: {
          required,
        },
        apiKey: {
          required,
        },
        paymentUrl: {
          required,
          validWebsiteUrl
        },
        returnUrl: {
          required,
          validWebsiteUrl
        },
        websiteUrl: {
          required,
          validWebsiteUrl
        },
        password: {
          required,
          alpha,
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs("password"),
        }
      },
      formEditData: {
        currency: {
          required,
        },
        min_deposit: {
          required,
        },
        max_deposit: {
          required,
        },
        payin_rate: {
          required,
          between: between(0.1,50)
        },
        payin_extra_charge: {
          required,
          between: between(0,10000)
        },
        payout_rate: {
          required,
          between: between(0.1,50)
        },
        payout_extra_charge: {
          required,
          between: between(0,10000)
        },
        settlement_rate: {
          required,
          between: between(0.0,50)
        },
        settlement_extra_charge: {
          required,
          between: between(0,10000)
        },
        ewallet_rate: {
          required,
          between: between(0.0,50)
        },
        ewallet_extra_charge: {
          required,
          between: between(0,10000)
        }
    }
    },
    middleware: "authentication",
    async mounted(){
     
      this.title = PageTitle
      this.items[1].text = PageTitle
      // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
      // this.$refs.main.changeVerticalTopBar("bill",true)
      // this.$refs.main.setShowFooterCert(false)
      // this.$refs.main.setPageTitle('title')
      await this.reload()
      this.accessToken = this.$refs.commonFunc.getToken()
      this.accessUsername = this.$refs.commonFunc.getUsername()
      // this.getData();
      // this.getCosting();
      this.getCurrency();
    }, 
    created(){
     
    },
    methods:{
      inputNumberOnlyV2(event, parentModel, childModel) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
        // if(numericValue.length > 2){
        //     var val = numericValue.replace(/[^0-9]/g, '');
        //     console.log(val);
        //     val = val.substr(0, val.length-2)+"."+val.substr(-2);
        //     this[parentModel][childModel] = val;
        // }
      },
      inputNumberOnlyV3(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9]/g, '');
        this[parentModel][index][childModel] = numericValue
      },
      handlePageChange(current_page) {
        this.pageNum = current_page;
        this.currentPage = current_page;
        //this.getData(this.pageNum, this.search_value);
        //this.exportData(1)
      },
      convertCurrencyFormat(value,show00) {
        if (show00==true) {
            return this.$refs.commonFunc.convertCurrencyFormat(value)
        }else{
            // Assuming this.transactionLimit is defined in your component's data
            return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
        }
        },
      inputNumberOnly(event) {
        var numericValue = event.target.value.replace(/[^0-9]/g, '');
        this.addBank.bank_account_no = numericValue;
        if (event.target.value !== numericValue) {
            return
        }
      },
      inputAmount(event) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this.addUPI.transaction_limit = numericValue;
        this.addBank.transaction_limit = numericValue;
        if (event.target.value !== numericValue) {
            return
        }
      },
      updateAmount() {
        const numericValue = this.addUPI.transaction_limit.replace(/[^0-9.]/g, '');
        if (numericValue !== '') {
            const amountNumber = parseInt(numericValue);
            if (!isNaN(amountNumber)) {
                this.addUPI.transaction_limit = amountNumber.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                    useGrouping: true
                });
                this.addBank.transaction_limit = amountNumber.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                    useGrouping: true
                });
            } else {
              this.addUPI.transaction_limit = '10,000';
              this.addBank.transaction_limit = '10,000';
            }
        } else {
          this.addUPI.transaction_limit = '10,000';
          this.addBank.transaction_limit = '10,000';
        }
      },
      viewExtraInfo(data) {
       this.$refs['modal-extra-info'].show()
       this.extraInfo = data;
      },
      editPg(value){
        this.updateData=value;
        setTimeout(() =>  this.$refs.modalFunc.showEditModal(),100)
      },
      resetModal() {
        this.extraInfo = {};
        this.modalSubmit = false;
        this.modalLoading = false;
        this.formData={
          username:"",
          name:"",
          paymentUrl: "",
          returnUrl: "",
          websiteUrl: "",
          password: "",
          confirmPassword: "",
        }
      },
      async getCurrency(){
          this.$Progress.start();
          this.loading = true
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername",  this.accessUsername);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/admin/getSupportedCurrency',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
              
                this.selectedCurrency = [currencyDefault]
                this.currencyList = []
                // var data = [
                //   {
                //     "name":"Malaysia (MYR)",
                //     "value":"MYR"
                //   },
                //   {
                //     "name":"India (INR)",
                //     "value":"INR"
                //   },
                //   {
                //     "name":"Indonesia (IDR)",
                //     "value":"IDR"
                //   },
                //   {
                //     "name":"Vietnam (VND)",
                //     "value":"VND"
                //   }
                // ]
                resData.data.forEach(element => {
                  this.currencyList.push(element)
                });
                if (resData.data.length > 0){
                  this.selectedCurrency =  this.currencyList[0]
                }
                this.getData()
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  this.loading = false;
              }
              this.$Progress.finish();
          })
          .catch((error)=> {
              this.loading = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      async getData(){
          this.$Progress.start();
          this.loading = true;
          this.formEditData.min_deposit = ''
          this.formEditData.max_deposit = ''
          this.returnData = [];
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername",  this.accessUsername);
          bodyFormData.append("currency",  this.selectedCurrency.value);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/admin/getOwnCostByCurrency',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                // resData.pg_list.forEach(element => {
                //   this.returnData.push({
                //     id: element.id,
                //     payment_db_code: element.payment_db_code,
                //     payment_gateway_name: element.payment_gateway_name,
                //     payment_gateway_code: element.payment_gateway_code,
                //     extra_info: JSON.stringify(element.extra_info),
                //     wallet_info: element.wallet_info,
                //     payin_rate: element.rate_info.payin_rate,
                //     payin_extra_charge: element.rate_info.payin_extra_charges,
                //     payout_rate: element.rate_info.payout_rate,
                //     payout_extra_charge: element.rate_info.payout_extra_charges,
                //     settlement_rate: element.rate_info.settlement_rate,
                //     settlement_extra_charge: element.rate_info.settlement_extra_charges,
                //     ewallet_rate: element.rate_info.ewallet_rate,
                //     ewallet_extra_charge: element.rate_info.ewallet_extra_charges,
                //     currency: element.currency,
                //     country: element.country,
                //     sequence: element.sequence,
                //     supported_type:  element.supported_type,
                //     min_deposit: element.rate_info.minumum_deposit,
                //     max_deposit: element.rate_info.maximum_deposit,
                //     balance_limit:  element.rate_info.balance_limit,
                //     categories:  element.categories,
                //     status:(element.status  === 'activated') ? 1 : 0,
                //   });
                // })
                //getCosting
                this.formEditData= {
                  id:"",
                  currency: this.selectedCurrency.value,
                  min_deposit: resData.own_cost_info.minumum_deposit,
                  max_deposit:resData.own_cost_info.maximum_deposit,
                  payin_rate: resData.own_cost_info.payin_rate,
                  payin_extra_charge: resData.own_cost_info.payin_extra_charges,
                  payout_rate: resData.own_cost_info.payout_rate,
                  payout_extra_charge:resData.own_cost_info.payout_extra_charges,
                  settlement_rate: resData.own_cost_info.settlement_rate,
                  settlement_extra_charge: resData.own_cost_info.settlement_extra_charges,
                  ewallet_rate: resData.own_cost_info.ewallet_rate,
                  ewallet_extra_charge: resData.own_cost_info.ewallet_extra_charges
                }
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.loading = false;
              this.$Progress.finish();
          })
          .catch((error)=> {
              this.loading = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      updateCompanyCost(){
        this.modalSubmit = true
        this.$v.formEditData.$touch();
        console.log((this.$v.formEditData.$invalid))
        if (this.$v.formEditData.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("currency", this.formEditData.currency);
          bodyFormData.append("minumumDeposit", this.formEditData.min_deposit);
          bodyFormData.append("maximumDeposit", this.formEditData.max_deposit);
          bodyFormData.append("payinRate", this.formEditData.payin_rate);
          bodyFormData.append("payinExtraCharges", this.formEditData.payin_extra_charge);
          bodyFormData.append("payoutRate", this.formEditData.payout_rate);
          bodyFormData.append("payoutExtraCharges", this.formEditData.payout_extra_charge);
          bodyFormData.append("settlementRate", this.formEditData.settlement_rate);
          bodyFormData.append("settlementExtraCharges", this.formEditData.settlement_extra_charge);
          bodyFormData.append("ewalletRate", this.formEditData.ewallet_rate);
          bodyFormData.append("ewalletExtraCharges", this.formEditData.ewallet_extra_charge);
    
          axios({
              method: "post",
              url:  appConfig.APIHostAdmin + 'controller/admin/updateCompanyCost',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'Company Cost Updated',
                  html: 'Company cost has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  console.log("success")
                  this.getData()
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
        });
      }
    },
      switchVisibility() {
        (this.classicon =
          this.classicon === "uil uil-eye-slash"
            ? "uil uil-eye"
            : "uil uil-eye-slash"),
          (this.passwordFieldType =
            this.passwordFieldType === "password" ? "text" : "password");
      },
      switchVisibility2() {
        (this.classicon2 =
          this.classicon2 === "uil uil-eye-slash"
            ? "uil uil-eye"
            : "uil uil-eye-slash"),
          (this.passwordFieldType2 =
            this.passwordFieldType2 === "password" ? "text" : "password");
      },
      
      updateStatus(value){
        this.$Progress.start();
        this.submitted = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("pgId", value.payment_db_code);
        bodyFormData.append("status", value.status);
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/activeInactivePg',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            this.returnData = []
            if (resData.status == 200) {
              console.log(resData)
              this.getData();
            } 
            else if (resData.status == 440){
              // Swal.fire(
              //     {
              //     icon: 'error',
              //     title: 'Oops...',
              //     html: `${resData.message}.`,
              //     confirmButtonColor: '#222',
              //     confirmButtonText: this.$t('siteLang.Done'),
              //   })
              //   localStorage.clear();
              //   this.$router.push({
              //       path: "/login",
              // });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.submitted = false;
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.submitted = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      updateSequence(){
        this.$Progress.start();
        this.modalLoading2 = true
        var bodyFormData = new FormData();
        var pgSequence = []
        this.returnData.forEach(element => {
          pgSequence.push(
            {
              'pgCode': element.payment_db_code,
              'sequence': element.sequence,
            })
        })
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("pgList", JSON.stringify(pgSequence));
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/updatePgSequence',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            this.returnData = []
            if (resData.status == 200) {
              console.log(resData)
              this.getData();
            } 
            else if (resData.status == 440){
              // Swal.fire(
              //     {
              //     icon: 'error',
              //     title: 'Oops...',
              //     html: `${resData.message}.`,
              //     confirmButtonColor: '#222',
              //     confirmButtonText: this.$t('siteLang.Done'),
              //   })
              //   localStorage.clear();
              //   this.$router.push({
              //       path: "/login",
              // });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading2 = false;
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading2 = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      
      removePaymentGateway(value) {
        Swal.fire(
          {
          icon: 'info',
          title: `Remove ${value.payment_gateway_name}?`,
          html: `Are you certain you want to remove ${value.payment_gateway_name} (${value.payment_gateway_code}). `,
          confirmButtonColor: '#222',
          confirmButtonText: "Yes, Remove",
          showCancelButton: true,
          cancelButtonColor: '#aaa',
          reverseButtons: true
        }).then((response) => {
            if (response.isConfirmed){
                this.$Progress.start();
                this.submitted = true
                var bodyFormData = new FormData();
                bodyFormData.append("accessToken", this.accessToken);
                bodyFormData.append("username",  this.accessUsername);
                bodyFormData.append("id", value);
                axios({
                    method: "post",
                    url: appConfig.DemoAPI,
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((response)=>{
                    this.returnData = []
                    var resData = response.data;
                    if (resData.status == 200) {
                      console.log(resData)
                      this.getData();
                    } 
                    else if (resData.status == 440){
                      Swal.fire(
                          {
                          icon: 'error',
                          title: 'Oops...',
                          html: `${resData.message}.`,
                          confirmButtonColor: '#222',
                          confirmButtonText: this.$t('siteLang.Done'),
                        })
                        localStorage.clear();
                        this.$router.push({
                            path: "/login",
                      });
                    }
                    else {
                        Swal.fire(
                          {
                          icon: 'error',
                          title: 'Oops...',
                          html: `${resData.message}.`,
                          confirmButtonColor: '#222',
                          confirmButtonText: this.$t('siteLang.Done'),
                        })
                    }
                    this.submitted = false;
                    this.$Progress.finish();
                  })
                  .catch((error)=> {
                      this.submitted = false;
                      this.$Progress.finish();
                      //Swal.fire("Error", error, "error");
                      Swal.fire(
                        {
                        icon: 'error',
                        title: 'Oops...',
                        html: `${error}.`,
                        confirmButtonColor: '#222',
                        confirmButtonText: this.$t('siteLang.Done'),
                      })
                });
            }
        })
      },  
      clear(){
        this.searchKey=""
        this.searchKey2=""
      },
      filterByResult() {
        return this.returnData.filter((e) => {
            return (
            e.payment_gateway_name.toLowerCase().includes(this.searchKey.toLowerCase())
            );
        })
      },
      
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
  
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
            const permission = data.modules.filter(e => e.name==='administrator')
            if (permission) {
              this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
              console.log(this.create_modify_permission)
            } else {
              console.log('Module with name "administrator" not found.');
            }
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
  
    }
  };
</script>
<style scoped>
  .multiselect{min-height: auto;}
  #eye_current,
  #eye,
  #eye2,
  #eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
  }
  .table td{
    padding: 0.75rem 0.75rem !important;
  }
</style>